:root {
  --color-text: #616161;
  --color-text-btn: #ffffff;
  --card1-gradient-color1: #abd7d1;
  --card1-gradient-color2: #86cdc4;
  --card2-gradient-color1: #86cdc4;
  --card2-gradient-color2: #68b4ac;
  --card3-gradient-color1: #68b4ac;
  --card3-gradient-color2: #4a9994;
}

.card-wrap {
  width: 300px;
  background: #fff;
  border-radius: 20px;
  border: none;
  overflow: hidden;
  color: var(--color-text);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

/* Media query for mobile devices */
@media screen and (max-width: 600px) {
  .card-wrap {
    width: 250px; /* Reduce width for mobile devices */
  }
}

.card-wrap::before {
  content: attr(data-step);
  position: absolute;
  top: 5px;
  left: 5px;
  background: #393837; /* Bright background color */
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.4rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.card-wrap:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.card-header {
  height: 200px;
  width: 100%;
  background: red;
  border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
  display: grid;
  place-items: center;
}

.card-header i,
.card-header svg {
  color: #fff;
  font-size: 72px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.card-btn {
  border: none;
  border-radius: 100px;
  padding: 5px 30px;
  color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.card-header.one {
  background: linear-gradient(
    to bottom left,
    var(--card1-gradient-color1),
    var(--card1-gradient-color2)
  );
}

.card-header.two {
  background: linear-gradient(
    to bottom left,
    var(--card2-gradient-color1),
    var(--card2-gradient-color2)
  );
}

.card-header.three {
  background: linear-gradient(
    to bottom left,
    var(--card3-gradient-color1),
    var(--card3-gradient-color2)
  );
}
