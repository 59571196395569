@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@200;300;400;500&family=Noto+Sans+JP:wght@100;300;400;500&family=Open+Sans:wght@300;400;500&family=Poppins:wght@100;200;300;400;500;600;700&family=Raleway:wght@100;200;300;400;500&display=swap");

@import "~@flaticon/flaticon-uicons/css/all/all";

* {
  font-family: "Poppins";
}

/* primary background colour for navbar */
.bg-primary {
  background-color: #0e776f;
}

/* buttons - need to specify width for each manually */
.btn-primary {
  @apply bg-teal-600 text-sm md:text-base shadow-md text-white rounded-md hover:bg-teal-700 transition-all duration-200;
}

.btn-secondary {
  @apply bg-gray-200 text-sm md:text-base shadow-md text-gray-800 rounded-md hover:bg-gray-300 transition-all duration-200;
}

.btn-inactive {
  @apply bg-orange-500 text-sm md:text-base text-white shadow-md font-semibold py-2 rounded-md  duration-200;
}

.btn-inactive:hover {
  @apply bg-orange-600 text-white font-bold shadow-lg;
}

.btn-edit,
.btn-back {
  @apply bg-yellow-300 text-sm md:text-base shadow-md text-yellow-800 rounded-md hover:bg-yellow-400  transition-all duration-200;
}

.btn-delete,
.btn-cancel {
  @apply bg-red-500 text-sm md:text-base shadow-md text-white rounded-md hover:bg-red-600  transition-all duration-200;
}

.btn-disabled {
  @apply bg-gray-200 text-sm md:text-base shadow-md py-2 rounded-md cursor-not-allowed duration-200;
}

.btn-continue,
.btn-confirm {
  @apply bg-emerald-500 text-sm md:text-base shadow-md text-white rounded-md hover:bg-emerald-600  transition-all duration-200;
}

.btn-stripe {
  @apply shadow-md text-white font-semibold py-2 rounded-md  duration-200;
}
.btn-stripe:hover {
  @apply font-bold shadow-lg;
}

.stripe-logo-colour {
  background-color: #6057f7;
}

.bg-waves {
  background-image: url("./assets/waves2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center calc(100% + 220px);
  width: 100%;
  min-height: 400px;
  /* margin-bottom: 20px; */
}

@media screen and (max-width: 600px) {
  .bg-waves {
    background-position: center calc(100% + 150px);
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Set flexbox properties for large screens */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #00cc66 transparent #00cc66 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Used within prize details to break line */
.new-line {
  white-space: pre-wrap;
}

/* Below classes required to ensure footer is always at bottom of screen */
.force-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Make the main content area grow to fill the available space */
.main-content {
  flex-grow: 1;
}

/* Remove previous and next arrows for Slider component */
.slick-prev,
.slick-next {
  display: none !important;
}

.input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  border: 1px solid #dadce0;
  border-radius: 7px;
  font-size: 16px;
  padding: 0 20px;
  outline: none;
  background: none;
  z-index: 1;
}

/* Hide the placeholder texts (a) */

::placeholder {
  color: transparent;
}

.label {
  font-weight: 600; /* Medium weight for the label text */
  margin-bottom: 5px; /* A bit of space between label and input field */
  color: #333; /* Dark gray color for the text */
  font-size: 16px;
}

input[type="text"],
input[type="number"],
textarea,
select {
  padding: 10px; /* Comfortable padding */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ddd; /* Light gray border */
  width: 100%; /* Make sure it occupies full width */
  font-size: 1rem; /* A comfortable font size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* A subtle shadow for depth */
}

input[type="file"] {
  padding: 8px 10px;
  background-color: white; /* 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* A subtle shadow for depth */
}

form {
  padding: 15px; /* Padding around the form */
  border-radius: 10px; /* Rounded corners */
  margin-bottom: 1rem;
}

/* FAQ on homepage */

.faq {
  width: 100%;
  box-sizing: border-box;
}

.faq-item {
  margin-bottom: 15px;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-answer.open {
  max-height: 400px;
}

/* Particles  */
#particles canvas {
  z-index: -1;
  position: relative;
  width: 100%;
  height: 100%;
}

.header__center {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  color: #374151;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.header__center::before,
.header__center::after {
  content: "";
  display: block;
  height: 1px;
  background-color: lightgray;
}

/* General reset for the range */
input[type="range"] {
  -webkit-appearance: none; /* Remove default appearance */
  width: 100%;
  background: transparent; /* Otherwise gets a white background in Chrome */
}

/* The track (background) */
input[type="range"]::-webkit-slider-runnable-track {
  height: 6px;
  background-color: #109588; /* Teal track color */
  border-radius: 3px;
}

input[type="range"]::-moz-range-track {
  height: 6px;
  background-color: #109588; /* Teal track color */
  border-radius: 3px;
}

/* The thumb (the draggable circle) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default look */
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #109588; /* Teal thumb */
  cursor: pointer;
  margin-top: -6px; /* Align thumb vertically in track */
}

input[type="range"]::-moz-range-thumb {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #109588; /* Teal thumb */
  cursor: pointer;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background: linear-gradient(90deg, #30353b, #15b8a6, #0e776f);
  background-size: 200% 200%;
  animation: gradientAnimation 4s ease infinite;
  /* Ensure text shows gradient in modern browsers */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
