/* DatePicker container to center the calendar */
.datepicker-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Day name row alignment */
.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-between !important;
  margin: 0 0.4rem !important;
}

/* Header styling */
.react-datepicker__header {
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #eee !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.4rem !important;
}

/* Month text styling */
.react-datepicker__current-month {
  font-size: 1rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.5rem !important;
}

/* Navigation styling */
.react-datepicker__navigation {
  top: 1rem !important;
}

/* Selected day styling */
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #109588 !important;
  color: white !important;
  border-radius: 4px !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .react-datepicker__month-container {
    margin: 0 !important;
    width: 100% !important;
  }
}
