.review-form {
  max-width: 400px;
  margin: auto;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.review-form h2 {
  text-align: center;
  margin-bottom: 1em;
}

.rating {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.star {
  font-size: 2em;
  cursor: pointer;
  transition: color 0.2s;
  color: darkgrey;
}

.star.hover {
  color: rgb(255, 217, 1);
}

label {
  display: block;
  margin-bottom: 1em;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: vertical;
}
